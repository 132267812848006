'use client';
import { Spinner } from '@air/primitive-spinner';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import { Slot } from '@radix-ui/react-slot';
import classNames from 'classnames';
import {
  cloneElement,
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
  isValidElement,
  type ReactNode,
} from 'react';

export const button = tailwindVariants({
  base: 'relative flex shrink-0 items-center justify-center rounded font-semibold transition-colors hover:no-underline disabled:cursor-not-allowed disabled:bg-grey-3 disabled:text-grey-12',
  variants: {
    appearance: {
      filled: 'border-0',
      ghost: 'border-0 bg-transparent',
      outline: 'border bg-transparent disabled:border-0',
    },
    color: {
      blue: '',
      grey: '',
      red: '',
    },
    size: {
      'extra-small': 'h-4 px-2 text-10',
      small: 'h-6 px-2 text-12',
      medium: 'h-8 px-2.5 text-14',
      large: 'h-10 px-3 text-14',
      'extra-large': 'h-12 px-4 text-16',
    },
  },
  compoundVariants: [
    {
      appearance: 'filled',
      color: 'blue',
      class: 'bg-blue-9 text-white hover:bg-blue-10 hover:text-white active:bg-blue-10',
    },
    {
      appearance: 'filled',
      color: 'grey',
      class: 'border border-grey-5 bg-grey-3 text-grey-11 hover:bg-grey-4 active:bg-grey-4',
    },
    {
      appearance: 'filled',
      color: 'red',
      class: 'bg-red-9 text-white hover:bg-red-10 hover:text-white active:bg-red-10',
    },
    {
      appearance: 'ghost',
      color: 'blue',
      class: 'text-blue-11 hover:bg-blue-1 active:bg-blue-1',
    },
    {
      appearance: 'ghost',
      color: 'grey',
      class: 'text-grey-11 hover:bg-grey-4 active:bg-grey-4',
    },
    {
      appearance: 'ghost',
      color: 'red',
      class: 'active:red-2 text-red-11 hover:bg-red-2',
    },
    {
      appearance: 'outline',
      color: 'blue',
      class: 'border-blue-11 text-blue-11 hover:bg-blue-1 active:bg-blue-1',
    },
    {
      appearance: 'outline',
      color: 'grey',
      class: 'border-grey-5 text-grey-11 hover:bg-grey-4 active:bg-grey-4',
    },
    {
      appearance: 'outline',
      color: 'red',
      class: 'border-red-11 text-red-11 hover:bg-red-2 active:bg-red-2',
    },
  ],
  defaultVariants: {
    appearance: 'filled',
    color: 'blue',
    size: 'medium',
  },
});

export const buttonSpinner = tailwindVariants({
  variants: {
    size: {
      'extra-small': 'h-2 w-2',
      small: 'h-3 w-3',
      medium: 'h-4 w-4',
      large: 'h-5 w-5',
      'extra-large': 'h-5 w-5',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export type ButtonVariants = VariantProps<typeof button>;

export type ButtonSpinnerVariants = VariantProps<typeof buttonSpinner>;

export const BUTTON_ELEMENT_TAG = 'button';

export type ButtonProps = Omit<ComponentPropsWithoutRef<typeof BUTTON_ELEMENT_TAG>, 'prefix' | 'suffix'> &
  ButtonVariants & {
    asChild?: boolean;
    isLoading?: boolean;
    prefix?: ReactNode;
    suffix?: ReactNode;
  };

export const Button = forwardRef<ElementRef<typeof BUTTON_ELEMENT_TAG>, ButtonProps>(
  (
    {
      appearance,
      asChild,
      children,
      className,
      color,
      disabled,
      isLoading,
      prefix,
      size,
      suffix,
      type = 'button',
      ...restOfProps
    },
    forwardedRef,
  ) => {
    const Component: any = asChild ? Slot : BUTTON_ELEMENT_TAG;
    const isSmall = !!size && ['extra-small', 'small'].includes(size);
    const childrenNode =
      asChild && isValidElement<any>(children) ? (
        cloneElement(children, {
          children: (
            <>
              {isLoading ? (
                <div className={classNames(isSmall ? 'mr-1' : 'mr-2')} role="status">
                  <VisuallyHidden>Loading...</VisuallyHidden>
                  <Spinner className={buttonSpinner({ size })} />
                </div>
              ) : prefix ? (
                <div className={classNames(isSmall ? 'mr-1' : 'mr-2')}>{prefix}</div>
              ) : null}
              {children.props.children}
              {suffix ? <div className={classNames(isSmall ? 'ml-1' : 'ml-2')}>{suffix}</div> : null}
            </>
          ),
        })
      ) : (
        <>
          {isLoading ? (
            <div className={classNames(isSmall ? 'mr-1' : 'mr-2')} role="status">
              <VisuallyHidden>Loading...</VisuallyHidden>
              <Spinner aria-hidden className={buttonSpinner({ size })} />
            </div>
          ) : prefix ? (
            <div className={classNames(isSmall ? 'mr-1' : 'mr-2')}>{prefix}</div>
          ) : null}
          {children}
          {suffix ? <div className={classNames(isSmall ? 'ml-1' : 'ml-2')}>{suffix}</div> : null}
        </>
      );

    return (
      <Component
        className={classNames(
          button({ appearance, color, size, class: className }),
          isLoading || disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        )}
        disabled={disabled}
        ref={forwardedRef}
        type={type}
        {...restOfProps}
      >
        {childrenNode}
      </Component>
    );
  },
);

Button.displayName = 'Button';
